import React, {Component} from 'react';

class Slider extends Component {
    state = {
        currentSlide: 0
    };

    handleNextSlide = () => {
        const {slides, onEnded} = this.props;

        if (this.state.currentSlide >= slides.length - 1) {
            onEnded();
            return null;
        } else {
            this.setState({currentSlide: this.state.currentSlide + 1})
        }
    }

    render() {
        const {slides, onEnded, ...rest} = this.props;

        return (
            <div {...rest} className='fill center-content' onClick={this.handleNextSlide}>
                {slides[this.state.currentSlide]}
            </div>
        );
    }
}

export default Slider;