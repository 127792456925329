import React, {Component} from 'react';
import PropTypes from "prop-types";

class SuspectBadge extends Component {

    static contextTypes = {
        scale: PropTypes.number,
    };

    render() {
        const {suspect, onSelect} = this.props;

        return (
            <div className='suspect-badge' onClick={() => onSelect(suspect)}>
                <img src={suspect.img}/>
                <div className='suspect-info' style={{
                    fontSize: `${this.context.scale * 18}px`,
                    lineHeight: `${this.context.scale * 20}px`,
                }}>
                    <span>Probabilidade</span>
                    <b>{this.renderProbability(suspect.probability)}%</b>
                </div>
            </div>
        );
    }

    renderProbability(probability) {
        return (probability * 100).toFixed(0);
    }
}

export default SuspectBadge;