import React, {Component} from 'react';
import SuspectBadge from "./SuspectBadge";

class SuspectsBar extends Component {


    handleSelectedSuspect = (suspect) => {
        const {onSelectSuspect} = this.props;
        onSelectSuspect(suspect);
    }

    render() {
        const {suspects} = this.props;

        if (suspects.length < 6) {
            return null;
        }

        return (
            <div id='suspects'>
                <div className='suspects-row'>
                    <SuspectBadge onSelect={this.handleSelectedSuspect} suspect={suspects[0]}/>
                    <SuspectBadge onSelect={this.handleSelectedSuspect} suspect={suspects[1]}/>
                </div>
                <div className='suspects-row'>
                    <SuspectBadge onSelect={this.handleSelectedSuspect} suspect={suspects[2]}/>
                    <SuspectBadge onSelect={this.handleSelectedSuspect} suspect={suspects[3]}/>
                </div>
                <div className='suspects-row'>
                    <SuspectBadge onSelect={this.handleSelectedSuspect} suspect={suspects[4]}/>
                    <SuspectBadge onSelect={this.handleSelectedSuspect} suspect={suspects[5]}/>
                </div>
            </div>
        );
    }
}

export default SuspectsBar;