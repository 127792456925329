import React, {Component} from 'react';
import CrimeSceneBackground from "../../assets/crime_scene.png";
import ItemBadge from "./ItemBadge";


class MurderRoom extends Component {

    handleItemSelected = (item) => {
        this.props.onSelectItem(item);
    }

    handleItemInteract = (item) => {
        this.props.onInteractItem(item);
    }

    render() {
        const {items} = this.props;
        return (
            <div id='murder-room' style={{backgroundImage: "url(" + CrimeSceneBackground + ")"}}>
                {items && items.map(item => (
                    <ItemBadge
                        key={item.id}
                        item={item}
                        onSelect={this.handleItemSelected}
                        onInteract={this.handleItemInteract}
                    />
                ))}
            </div>
        );
    }
}

export default MurderRoom;