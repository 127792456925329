import React, {Component} from 'react';
import ReactPlayer from 'react-player'
import IntroVideo from '../../assets/DroddIntro.mp4'

class Introduction extends Component {

    componentDidMount() {
        window.addEventListener('keypress', this.handleKeyPress);
    }

    componentWillUnmount() {
        window.removeEventListener('keypress', this.handleKeyPress);
    }

    handleKeyPress = (e) => {
        this.props.onEnd()
    }

    render() {
        return <ReactPlayer
            url={IntroVideo}
            playing
            controls={false}
            playsinline
            width={'100%'}
            height={'100%'}
            loop={false}
            onEnded={this.handleVideoEnded}
        />
    }

    handleVideoEnded = () => {
        this.props.onEnd();
    }
}


export default Introduction;
