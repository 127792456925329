import React, {Component} from 'react';
import {Loop, Stage} from 'react-game-kit';

import './App.scss';
import Game from "./components/Game/Game";

class App extends Component {
    render() {
        return (
            <Loop>
                <Stage width={1920} height={1080}>
                    <Game/>
                </Stage>
            </Loop>
        );
    }
}

export default App;
