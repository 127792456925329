import React, {Component} from 'react';
import Modal from 'react-responsive-modal';

import './Play.scss'
import SuspectsBar from "./SuspectsBar";
import {getViewportRef} from "../../components/Game/Game";
import MurderRoom from "./MurderRoom";
import Items, {getRandomItems} from "../../models/items";
import suspects from "../../models/suspects";
import Slider from "../../components/Slider/Slider";
import {FoundItemCard, FoundItemCardWithPolice, SuspectsOverview} from "./ItemFoundSlides";
import {SuspectPercentage, SuspectSelected} from "./SuspectSelectedSlides";

export default class Play extends Component {

    state = {
        isFoundItemModalOpen: false,
        isSuspectSelectedOpen: false,
        foundItem: null,
        selectedSuspect: null,
        items: [],
        suspects: suspects
    }

    componentDidMount() {
        this._getItems();
        this._updateSuspectsProbability();
    }

    _getItems() {
        // @TODO select items randonly?

        this.setState({
            items: getRandomItems()
        })
    }

    _updateSuspectsProbability() {
        this.state.suspects.forEach(suspect => {
            suspect.probability = 0;

            this.state.items.filter((item) => item.isFound).forEach(item => {
                suspect.probability += item.wrongProbability * item.probabilities[suspect.id]
            });
        });
        this.setState({suspects: this.state.suspects});
    }

    handleSuspectSelected = (suspect) => {
        this.setState({
            isSuspectSelectedOpen: true,
            selectedSuspect: suspect
        });
    }

    handleItemSelected = (item) => {
        item.isFound = true;
        this._updateSuspectsProbability()
        this.setState({
            isFoundItemModalOpen: true,
            foundItem: item
        });
    }

    handleItemInteract = (item) => {
        item.isActive = !item.isActive;
        this.setState({});
    }

    render() {
        return (
            <div id={'play-stage'}>
                <MurderRoom items={this.state.items} onSelectItem={this.handleItemSelected}
                            onInteractItem={this.handleItemInteract}/>
                <SuspectsBar suspects={this.state.suspects} onSelectSuspect={this.handleSuspectSelected}/>

                {this.renderItemModal()}
                {this.renderSuspectModal()}
            </div>
        );
    }

    renderItemModal(item) {
        return (
            <Modal open={this.state.isFoundItemModalOpen}
                   onClose={() => this.setState({isFoundItemModalOpen: false})}
                   center
                   showCloseIcon={false}
                   container={getViewportRef()}
                   classNames={{'overlay': 'default-overlay', 'modal': 'default-modal'}}
            >
                <Slider
                    slides={[
                        <FoundItemCard item={this.state.foundItem} key='found-item-card'/>,
                        <FoundItemCardWithPolice item={this.state.foundItem} key='found-item-card-police'/>,
                        <SuspectsOverview suspects={this.state.suspects}
                                          items={this.state.items.filter(i => i.isFound)} key='suspects-overview'/>
                    ]}
                    onEnded={() => this.setState({isFoundItemModalOpen: false})}/>
            </Modal>
        )
    }

    renderSuspectModal() {
        return (
            <Modal open={this.state.isSuspectSelectedOpen}
                   onClose={() => this.setState({isSuspectSelectedOpen: false})}
                   center
                   showCloseIcon={false}
                   container={getViewportRef()}
                   classNames={{'overlay': 'default-overlay', 'modal': 'default-modal'}}
            >
                <Slider
                    slides={[
                        <SuspectSelected
                            suspect={this.state.selectedSuspect}
                            level={0}
                            foundItems={this.state.items.filter(i => i.isFound)}
                            key='suspect-1'
                        />,
                        <SuspectSelected
                            suspect={this.state.selectedSuspect}
                            level={1}
                            foundItems={this.state.items.filter(i => i.isFound)}
                            key='suspect-2'
                        />,
                        <SuspectSelected
                            suspect={this.state.selectedSuspect}
                            level={2}
                            foundItems={this.state.items.filter(i => i.isFound)}
                            key='suspect-3'
                        />
                    ]}
                    onEnded={() => this.setState({isSuspectSelectedOpen: false})}/>
            </Modal>
        )
    }
}
