import React from 'react';
import PoliceImg from '../../assets/SherlockB.png'
import {percent} from "../../components/Utils/Utils";

const FoundItemCard = ({item, displayPolice = false}) => {
    return (
        <div className='item-found-slide-found-item-card'>
            <img src={item.foundImg}/>
            {displayPolice &&
            [
                <img className='police-img' src={PoliceImg}/>,
                <span className='item-found-slide-found-item-card-probability'>
                    {percent(item.wrongProbability)} %
                </span>
            ]
            }
        </div>
    );
};

const FoundItemCardWithPolice = ({item}) => {
    return (<FoundItemCard item={item} displayPolice/>);
};


const SuspectsOverview = ({suspects, items}) => {
    return (
        <div className='card item-found-slide-suspects-overview'>
            <table>
                <thead>
                <tr>
                    <td><h3>Suspeitos</h3></td>
                    {suspects.map((suspect) => (
                        <td key={suspect.id}>
                            <img className='suspect-overview-badge' src={suspect.img}/><br/>
                            <span style={{opacity: 0.7}}>{percent(suspect.probability)}%</span>
                        </td>
                    ))}
                </tr>
                </thead>
                <tbody>
                {items.map(item => (
                    <tr>
                        <td key={item.id}>
                            <img src={item.foundImg} style={{maxHeight: '60px'}}/>
                        </td>
                        {suspects.map((suspect) => (
                            <td className='suspect-percent'>{percent(item.probabilities[suspect.id])}%</td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};


export {FoundItemCard, FoundItemCardWithPolice, SuspectsOverview};
