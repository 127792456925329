import React, {Component} from 'react';
import Logo from '../../assets/logo.png'
import LogoText from '../../assets/LogoToca_no_Ecran.png'
import './start.scss'

class Start extends Component {
    componentDidMount() {
        window.addEventListener('keypress', this.handleKeyPress);
    }

    componentWillUnmount() {
        window.removeEventListener('keypress', this.handleKeyPress);
    }

    handleKeyPress = (e) => {
        this.props.onEnd()
    }

    render() {
        return (
            <div id='start' style={{backgroundImage: `url("${Logo}")`}} onClick={() => this.handleKeyPress()}>
                <img className='logo-text-msg' src={LogoText} style={styles.pressKeyMsg}/>
            </div>
        );
    }
}

const styles = {}

export default Start;