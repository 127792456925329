import React, {Component} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types'

class ItemBadge extends Component {
    static contextTypes = {
        scale: PropTypes.number,
    };

    handleItemSelected = () => {
        const {item, onSelect, onInteract} = this.props;

        onInteract(item);

        if (!item.isFound) {
            onSelect(item)
        }
    }

    render() {
        const {item} = this.props;
        return (
            <div
                className={classNames('item-badge', {'is-found': item.isFound})}
                onClick={this.handleItemSelected}
                style={{
                    left: `${item.position.x}%`,
                    top: `${item.position.y}%`,
                    transform: `scale(${this.context.scale})`
                }}
            >
                <img src={(item.isActive) ? item.imgActive : item.img}/>
            </div>
        );
    }
}

export default ItemBadge;