const Suspects = [
    {
        id: 'CONDESSA',
        name: 'Condessa',
        img: require('../assets/suspects/Condessa.png')
    },
    {
        id: 'CUSTODIO',
        name: 'Custodio',
        img: require('../assets/suspects/Custodio.png')
    },
    {
        id: 'INDIO',
        name: 'Indio',
        img: require('../assets/suspects/Indio.png')
    },
    {
        id: 'ALBERTO',
        name: 'Alberto',
        img: require('../assets/suspects/Alberto.png')
    },
    {
        id: 'COUTO',
        name: 'Couto',
        img: require('../assets/suspects/Couto.png')
    },
    {
        id: 'OFELIA',
        name: 'Ofelia',
        img: require('../assets/suspects/Ofelia.png')
    },
];

export default Suspects.slice(0);