import React from 'react';
import {percent} from "../../components/Utils/Utils";

const SuspectSelected = ({suspect, level, foundItems}) => {
    let renderItems = renderItemsLevel0(suspect, foundItems)
    let renderItems1 = renderItemsLevel1(suspect, foundItems)
    return (
        <div className='suspect-selected-card'>
            <div className='explanation'>
                <h1 style={{textAlign: 'center'}}>A probabilidade do assassino ser o(a) {suspect.name} é</h1>
                <div style={{padding: 20}}>
                    {level >= 0 && (
                        <div className='formula-row'>
                            {renderItems}
                        </div>
                    )}
                    {level >= 1 && (
                        <div className='formula-row'>
                            {renderItems1}
                        </div>
                    )}

                    {level >= 2 && (
                        <h1 style={{textAlign: 'center', fontSize: '50px'}}>
                            {percent(suspect.probability)}%
                        </h1>
                    )}
                </div>
            </div>
        </div>
    );
};

const renderItemsLevel0 = (suspect, foundItems) => {
    let renderItems = [
        <p>
            <label>Probablilidade</label>
            P
        </p>,
        <p>
            <label>{suspect.name}</label>
            (D)
        </p>,
        <p>=</p>
    ]
    foundItems.forEach((item, index) => {
        renderItems.push(
            <p key={Math.random() * 1}>
                <label>Probabilidade</label>
                P({item.name})
            </p>
        )
        renderItems.push(<p key={Math.random() * 2}>x</p>)
        renderItems.push(
            <p key={Math.random() * 3}>
                <label>Probabilidade {suspect.name}</label>
                P
            </p>
        )
        renderItems.push(
            <p key={Math.random() * 4}>
                <label>sabendo que</label>
                (D | {item.name})
            </p>
        )
        if (index !== (foundItems.length - 1)) {
            renderItems.push(<p key={Math.random() * 5}>+</p>)
        }
    })
    return renderItems;
};

const renderItemsLevel1 = (suspect, foundItems) => {
    let renderItems = [
        <p>
            P
        </p>,
        <p>
            (D)
        </p>,
        <p>=</p>
    ]
    foundItems.forEach((item, index) => {
        renderItems.push(
            <p key={Math.random() * 1}>
                P({item.wrongProbability})
            </p>
        )
        renderItems.push(<p key={Math.random() * 2}>x</p>)
        renderItems.push(
            <p key={Math.random() * 3}>
                P
            </p>
        )
        renderItems.push(
            <p key={Math.random() * 4}>
                ({item.probabilities[suspect.id]})
            </p>
        )
        if (index !== (foundItems.length - 1)) {
            renderItems.push(<p key={Math.random() * 5}>+</p>)
        }
    })
    renderItems.push(
        <p>=</p>
    )
    renderItems.push(
        <p>{percent(suspect.probability) / 100}</p>
    )
    return renderItems;
};

export {SuspectSelected};
