import React, {Component} from 'react';
import Introduction from "../../stages/Introduction/Introduction";
import Start from "../../stages/Start/Start";
import Play from "../../stages/Play/Play";


class Game extends Component {

    constructor(props) {
        super(props);

        this.state = {
            scene: 'START'
        }
    }

    render() {
        return (
            <div id='viewport' ref={ref => ViewportRef = ref}>
                {this.renderScene()}
            </div>
        )
    }

    renderScene() {
        switch (this.state.scene) {
            case 'START':
                return <Start onEnd={() => this.setState({scene: 'INTRO'})}/>;
            case 'INTRO':
                return <Introduction onEnd={() => this.setState({scene: 'PLAY'})}/>;
            case 'PLAY':
                return <Play/>;
        }
    }
}

var ViewportRef = null;
const getViewportRef = () => ViewportRef;
export {getViewportRef}

export default Game;