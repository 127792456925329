const Items = [
    {
        id: 1,
        name: 'Botão de Punho',
        category: 'SOFA',
        img: require('../assets/items/SofaFechado.png'),
        imgActive: require('../assets/items/SofaAberto.png'),
        position: {x: 76.4, y: 53.3},
        foundImg: require('../assets/items/found_card/Pista_Botao.png'),
        wrongProbability: 0.3,
        wrongText: 'Atenção que a Empregada costuma usar esta sala para fazer costura',
        probabilities: {
            ALBERTO: 0,
            CONDESSA: 0,
            COUTO: 0.5,
            CUSTODIO: 0,
            INDIO: 0.5,
            OFELIA: 0
        }
    },
    {
        id: 2,
        name: 'Cabelo Loiro',
        category: 'LIVROS',
        img: require('../assets/items/LivrosFechado.png'),
        imgActive: require('../assets/items/LivrosAberta.png'),
        position: {x: 10.5, y: 25},
        foundImg: require('../assets/items/found_card/Pista_CabeloLoiro.png'),
        wrongProbability: 0.7,
        wrongText: 'Mas a Mãe do Dr. ODD visitou-o no dia anterior e tem cabelo loiro',
        probabilities: {
            ALBERTO: 0,
            CONDESSA: 0.5,
            COUTO: 0,
            CUSTODIO: 0.7,
            INDIO: 1,
            OFELIA: 1
        }
    },
    {
        id: 3,
        name: 'Cabelo Castanho',
        category: 'LIVROS',
        img: require('../assets/items/LivrosFechado.png'),
        imgActive: require('../assets/items/LivrosAberta.png'),
        position: {x: 10.5, y: 25},
        foundImg: require('../assets/items/found_card/Pista_CabeloCastanho.png'),
        wrongProbability: 0.5,
        wrongText: '',
        probabilities: {
            ALBERTO: 0,
            CONDESSA: 0,
            COUTO: 0,
            CUSTODIO: 0.3,
            INDIO: 0,
            OFELIA: 0
        }
    },
    {
        id: 4,
        name: 'Cabelo Preto',
        category: 'LIVROS',
        img: require('../assets/items/LivrosFechado.png'),
        imgActive: require('../assets/items/LivrosAberta.png'),
        position: {x: 10.5, y: 25},
        foundImg: require('../assets/items/found_card/Pista_CabeloPreto.png'),
        wrongProbability: 0.5,
        wrongText: '',
        probabilities: {
            ALBERTO: 0,
            CONDESSA: 0,
            COUTO: 0,
            CUSTODIO: 0.3,
            INDIO: 0,
            OFELIA: 0
        }
    },
    {
        id: 5,
        name: 'Pena',
        category: 'SOFA',
        img: require('../assets/items/SofaFechado.png'),
        imgActive: require('../assets/items/SofaAberto.png'),
        position: {x: 76.4, y: 53.3},
        foundImg: require('../assets/items/found_card/Pista_Pena.png'),
        wrongProbability: 0.4,
        wrongText: '',
        probabilities: {
            ALBERTO: 0,
            CONDESSA: 0,
            COUTO: 0,
            CUSTODIO: 0.3,
            INDIO: 0,
            OFELIA: 0
        }
    },
    {
        id: 6,
        name: 'Bocado de cordão azul',
        category: 'SOFA',
        img: require('../assets/items/SofaFechado.png'),
        imgActive: require('../assets/items/SofaAberto.png'),
        position: {x: 76.4, y: 53.3},
        foundImg: require('../assets/items/found_card/Pista_CordaoAzul.png'),
        wrongProbability: 0.3,
        wrongText: '',
        probabilities: {
            ALBERTO: 0,
            CONDESSA: 0,
            COUTO: 0,
            CUSTODIO: 0.3,
            INDIO: 0,
            OFELIA: 0
        }
    },
    {
        id: 7,
        name: 'Pestana postiça',
        category: 'TERRINA',
        img: require('../assets/items/TerrinaFechada.png'),
        imgActive: require('../assets/items/TerrinaAberta.png'),
        position: {x: 23.7, y: 45.6},
        foundImg: require('../assets/items/found_card/Pista_Pestana.png'),
        wrongProbability: 0.4,
        wrongText: '',
        probabilities: {
            ALBERTO: 0,
            CONDESSA: 0,
            COUTO: 0,
            CUSTODIO: 0.3,
            INDIO: 0,
            OFELIA: 0
        }
    },
    {
        id: 8,
        name: 'Unha de gel',
        category: 'TERRINA',
        img: require('../assets/items/TerrinaFechada.png'),
        imgActive: require('../assets/items/TerrinaAberta.png'),
        position: {x: 23.7, y: 45.6},
        foundImg: require('../assets/items/found_card/Pista_Unha.png'),
        wrongProbability: 0.4,
        wrongText: '',
        probabilities: {
            ALBERTO: 0,
            CONDESSA: 0,
            COUTO: 0,
            CUSTODIO: 0.3,
            INDIO: 0,
            OFELIA: 0
        }
    },
];


export const getRandomItems = () => {
    const itemsByCategory = {
        SOFA: {items: []},
        TERRINA: {items: []},
        LIVROS: {items: []}
    }
    const selectedItems = [];

    Items.forEach(item => {
        itemsByCategory[item.category].items.push(Object.assign({}, item))
    })

    Object.keys(itemsByCategory).forEach(category => {
        const allItems = itemsByCategory[category].items;
        const randomPos = Math.floor(Math.random() * allItems.length)
        selectedItems.push(allItems[randomPos]);
    })

    return selectedItems;
}

export default Items;